.footer {
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 15px;
  /* background-color: #27c4f5; */
  /* width: 100%; */
  left: 300px;
}
@media (max-width: 1200px) {
  .footer {
    left: 0 !important;
  }
}

#error {
  height: 100vh;
  background-color: #ebf3ff;
  padding-top: 5rem;
  .img-error {
    width: 100%;
  }
  .error-title {
    font-size: 4rem;
    margin-top: 3rem;
  }
}

.img-preview {
  width: 100%;
  max-width: 80px;
}
.pointer {
  :hover {
    cursor: pointer !important;
  }
}
