.nav-pills {
    .nav-link {
        &.active {
            box-shadow: 0 2px 10px rgba(map-get($theme-colors, 'primary'), .5);
        }
    }
}
.nav-tabs {
    border: none ;
    .nav-link {
        border: none;
    }
    .nav-link:hover {
        border:none;
        text-shadow: 0 0 2px rgba(map-get($theme-colors, 'primary'),.3);
    }
     .nav-link.active {
        border: none; 
        position: relative;
        color: map-get($theme-colors, 'primary');
        &:after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: map-get($theme-colors, 'primary');
            left: 0;
            box-shadow: 0 2px 5px rgba(map-get($theme-colors, 'primary'),.5);
        }        
     }
}