.modal {
    .modal-content {
        box-shadow: -8px 12px 18px 0 rgba(25,42,70,.13);
        border: none;
    }
    .modal-full {
        max-width: 94%
    }
    .white {
        color: white;
    }
    .modal-header {
        display:flex;
        justify-content: space-between;
        align-items: center;
        .modal-title {
            font-size: $modal-header-font-size;
        }
        .close {
            padding: 7px 10px;
            border-radius: 50%;
            background:none;
            border: none;
            &:hover {
                background: $gray-300
            }
        }
        i, svg {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }
    }
    .modal-footer {
        padding: $modal-inner-padding;
    }

    &.modal-borderless {
        .modal-header {
            border-bottom: 0;
        }
        .modal-footer {
            border-top: 0;
        }
    }
}