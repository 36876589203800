.auth {
  height: 100vh;
  overflow-x: hidden;

  .authRight {
    height: 100%;
    background: linear-gradient(90deg, #05b1a1, #05b1a1);
  }
  .authLeft {
    padding: 5rem 8rem;

    // .authTitle {
    //   font-size: 2rem;
    //   margin-bottom: 1rem;
    // }
    .authSubtitle {
      font-size: 1.7rem !important;
      line-height: 2.5rem;
      color: #a8aebb;
    }
    // .authLogo {
    //   margin-bottom: 2rem;
    //   img {
    //     height: 2rem;
    //   }
    // }
    @media screen and (max-width: 767px) {
      // max-width: 300px !important;
      padding: 3rem;
    }
  }
}
